var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            visible: _vm.visible,
            title: "选择证明类型",
            "cancel-text": "上一步",
            size: "large",
            "ok-text": "确认",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "div",
            { staticClass: "view" },
            _vm._l(_vm.typeData, function (item) {
              return _c(
                "div",
                {
                  key: item.proveTempId,
                  staticClass: "view-type",
                  class:
                    item.proveTempId == _vm.selectVal
                      ? "view-type select-div"
                      : "",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.getValue(item)
                    },
                  },
                },
                [
                  _c("a-icon", {
                    staticClass: "v-icon",
                    attrs: { type: "file-word" },
                  }),
                  _vm._v(" " + _vm._s(item.tempName) + " "),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }